
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: { CustomSelect },
})
export default class PosMarketFilter extends Vue {
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    get pos() {
        return this.documentFiltersService.storeState.settings.pos;
    }
    set pos(value) {
        this.documentFiltersService.storeState.settings.pos = value;
    }
    get posItems(): Item[] {
        return this.documentFiltersService.posMarketItems;
    }
}
