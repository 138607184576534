
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';

import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import UserSettings, { UserSettingsS } from '@/modules/user/user-settings.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';

@Component({
    components: {
        CustomSelect,
        CustomMultiSelect,
    },
})
export default class EmailReportsSubpage extends Vue {
    static title = 'settings.reports.htmlTitle';

    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(UserSettingsS) private userSettings!: UserSettings;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;

    isChanged = false;
    isError = false;
    isSaving = false;
    statusText = '';

    updateChangeStatus() {
        this.isChanged = true;
    }

    rangeItems = [5, 7, 14].map(value => ({
        name: this.$t('numDays', [value]).toString(),
        value,
    }));

    selectedCompsets_: string[] | null = null;
    compsetProperties_ = {
        provider: '',
        customizedRange: 5,
    };

    get compsetProperties() {
        const { provider } = this.compsetProperties_;
        const isProviderExists = this.sourceList.some(item => item.value === provider);

        if (!provider || !isProviderExists) {
            this.compsetProperties_.provider = this.sourceList[0]?.value || '';
            this.isChanged = false;
        }

        return this.compsetProperties_;
    }

    set compsetProperties(value) {
        this.compsetProperties_ = value;
    }

    get currentCompsetsForReports() {
        const { emailHtmlReportSettings } = this.userSettings;
        if (!emailHtmlReportSettings) return [];

        this.selectedCompsets_ = null;
        Object.assign(this.compsetProperties_, emailHtmlReportSettings[0] || {});

        return emailHtmlReportSettings.map(c => c.compsetId);
    }

    get selectedCompsets() {
        if (this.selectedCompsets_ === null) {
            this.selectedCompsets_ = this.currentCompsetsForReports;
        }

        return this.selectedCompsets_;
    }

    set selectedCompsets(value) {
        this.selectedCompsets_ = value;
    }

    get sourceList() {
        const compsets = this.compsetsService.compsets || [];

        const rawProviders = compsets
            .map(compset => compset.rateProviders)
            .flat() as string[];

        const providers = Array.from(new Set(rawProviders));
        const ignoredProviders = ['cheapest', 'all'];

        return providers
            .filter(value => !ignoredProviders.includes(value))
            .map(value => {
                const name = this.providersService
                    .getProviderLabel(value);
                return { name, value };
            });
    }

    get compsetsItems() {
        const compsets = this.compsetsService.compsets || [];
        const selectedProvider = this.compsetProperties_.provider;

        return compsets.map(compset => {
            const disabled = !compset.rateProviders.includes(selectedProvider);

            if (disabled && this.selectedCompsets.includes(compset.id)) {
                this.selectedCompsets = this.selectedCompsets.filter(id => id !== compset.id);
            }

            return {
                name: compset.name,
                value: compset.id,
                disabled,
            };
        });
    }

    get isCompsetsChanged() {
        return (this.selectedCompsets_)!.join(',') !== (this.currentCompsetsForReports).join(',');
    }

    get isCompsetsValid() {
        return this.selectedCompsets.length > 0;
    }

    get isAbleToSave() {
        if (!this.isCompsetsValid && this.isCompsetsChanged) return false;

        return (this.isChanged || this.isCompsetsChanged) && !this.isSaving;
    }

    get payload() {
        return this.selectedCompsets.map(compsetId => ({
            ...this.compsetProperties_,
            compsetId,
        }));
    }

    async saveSettings() {
        this.statusText = '';
        this.isError = false;
        this.isSaving = true;

        try {
            await this.userSettings.updateCompsetReportsSettings(this.payload);

            this.statusText = this.$tc('saved');
            this.isChanged = false;
        } catch {
            this.statusText = this.$tc('somethingWrong');
            this.isError = true;
        } finally {
            this.isSaving = false;
        }
    }
}
