
import moment from 'moment';
import { Prop, Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';

import Currency from '@/modules/common/components/currency.vue';
import PriceFilter from '@/modules/common/filters/price.filter';
import Flag from '@/modules/common/components/ui-kit/flag.vue';
import Day from '@/modules/common/types/day.type';
import { ASSESSMENT_COLORS, ASSESSMENT_TYPES, COLOR_NAME } from '@/modules/common/constants';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';

import RatesCheckinDayModel from '@/modules/rates/models/rates-checkin-day.model';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import PAGES from '@/modules/common/constants/pages.constant';
import CompsetModel from '@/modules/compsets/models/compset.model';

import RatesCalendarLine from './rates-calendar-line.vue';
import DayTooltip from '../../_common/day-tooltip.vue';

const DEFAULT_TOOLTIP_DATA = {
    color: COLOR_NAME.GRAY,
    compsetPercent: null,
    price: null,
    date: null,
    pos: null,
    priceStatus: '',
};

const NULL_COMPSET = new CompsetModel();

@Component({
    filters: { PriceFilter },
    components: {
        RatesCalendarLine,
        DayTooltip,
        Flag,
        Currency,
    },
})
export default class RatesDaysSet extends Vue {
    @inject(ClusterRatesServiceS) private clusterRatesService!: ClusterRatesService;
    @inject(ClusterServiceS) private clusterService!: ClusterService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;

    @Prop({ type: Object, default: () => ({}) })
    hotelData!: ClusterHotelsRatesModel;

    isCalendarLoading = false;

    tooltipData: {
        pos: HTMLElement | null;
        color: COLOR_NAME;
        date: Date | null;
        compsetPercent: number | null;
        price: number | null;
        priceStatus: string;
    } = DEFAULT_TOOLTIP_DATA;

    get currency() {
        return this.ratesCommonService
            .currency(this.hotelData.compsetMain!);
    }

    get compset() {
        if (!this.hotelData.compsetMain) {
            return NULL_COMPSET;
        }

        return this.clusterService
            .getCompset(
                this.hotelData.compsetMain.id,
                this.hotelData.hotelId,
            )!;
    }

    get isLoading() {
        return this.isCalendarLoading || !this.hotelData.documentLoaded;
    }

    get formatedScoreDifference() {
        if (this.isLoading) return '';

        const score = Math.round(this.hotelData.newTotalScore) || 0;
        return `${score > 0 ? '+' : ''}${score}`;
    }

    get isNoData() {
        return !this.compset || !this.compset.type;
    }

    get isOutOfRange() {
        return !this.hotelData.compsetMain;
    }

    get hasOtherEvents() {
        if (!this.tooltipData.date) return false;
        const { date } = this.tooltipData;

        return this.eventsManagerService
            .hasDateOtherEvents(date, this.hotelData.hotelId);
    }

    get flagList() {
        if (!this.tooltipData.date) return [];

        const { date } = this.tooltipData;
        const events = this.eventsManagerService.getHolidayEvents(date);

        return Array.from(
            new Set(
                events.map(e => e.countryCode),
            ),
        );
    }

    get flagsMoreBy() {
        return this.flagList.length - 3;
    }

    get date() {
        const { date } = this.tooltipData;
        if (!date) return '';
        return moment(date).format('ddd, MMM DD, YYYY');
    }

    /**
     * Lazy load of document, send request only if component is in viewport.
     */
    async loadViewportedHotel(isVisible: boolean) {
        if (this.isCalendarLoading || !isVisible || this.hotelData.documentLoaded) {
            return;
        }

        this.isCalendarLoading = true;
        await this.clusterService
            .loadDaysData(PAGES.RATES, this.hotelData.hotelId)
            .finally(() => {
                this.isCalendarLoading = false;
            });
    }

    setActiveDay(el: HTMLDivElement) {
        const day = +el.dataset.day! as Day;
        const { year, month } = this.documentFiltersService;
        const date = new Date(year, month, day);

        if (this.isNoData || this.isOutOfRange) {
            this.tooltipData = {
                pos: el,
                color: ASSESSMENT_COLORS[ASSESSMENT_TYPES.SOLD_OUT],
                date,
                compsetPercent: null,
                price: null,
                priceStatus: this.isOutOfRange ? this.$tc('outOfRange') : this.$tc('noData'),
            };
            return;
        }

        const { hotelId } = this.hotelData;
        const isNa = this.clusterRatesService.isNa(day, hotelId);
        const type = isNa
            ? ASSESSMENT_TYPES.NA
            : el.dataset.type! as unknown as ASSESSMENT_TYPES;
        const price = this.clusterRatesService.getPrice(day, hotelId);

        let compsetPercent = this.clusterRatesService.getCompetitionPercent(day, hotelId);
        let priceStatus = '';

        compsetPercent = compsetPercent !== null
            ? Math.round(compsetPercent * 100)
            : null;

        if (this.clusterRatesService.isNa(day, hotelId)) {
            priceStatus = this.$tc('na');
        } else if (this.clusterRatesService.isSoldOut(day, hotelId)) {
            const cd = this.clusterRatesService.getCheckinDay(day, hotelId) as RatesCheckinDayModel;
            const pax = cd ? cd.hotels[hotelId]?.pax : null;

            priceStatus = pax && pax.length
                ? this.$tc('nog', 0, [pax])
                : this.$tc('soldOut');
        } else if (this.clusterRatesService.isNoData(day, hotelId)) {
            priceStatus = this.$tc('noData');
        }

        const newTooltipData = {
            pos: el,
            color: ASSESSMENT_COLORS[type],
            date,
            compsetPercent,
            price,
            priceStatus,
        };

        this.tooltipData = newTooltipData;
    }

    hideTooltip() {
        this.tooltipData = DEFAULT_TOOLTIP_DATA;
    }

    async onCellClick(element: HTMLDivElement) {
        const day = Number(element.getAttribute('data-day'));

        if (!this.hotelData) return;
        if (this.isOutOfRange) return;

        const { id: compsetId } = this.hotelData.compsetMain || this.hotelData.compsets[0] || {};
        const { hotelId } = this.hotelData;

        if (!compsetId) return;

        await this.$router.push({
            name: `${this.$route.name!}.day-rate`,
            params: {
                day: String(day),
                hotelId: String(hotelId),
                compsetId: String(compsetId),
            },
        });
    }
}
